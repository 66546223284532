import React from 'react';

const CustomerSupport: React.FC<React.SVGProps<SVGSVGElement>> = ({
  ...props
}) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666626 33.968C0.666626 30.5824 3.28101 27.968 6.66663 27.968H16.6666C20.0522 27.968 22.6666 30.5824 22.6666 33.968V38.968C22.6666 39.5203 22.2189 39.968 21.6666 39.968H1.66663C1.11434 39.968 0.666626 39.5203 0.666626 38.968V33.968ZM6.66663 29.968C4.38558 29.968 2.66663 31.687 2.66663 33.968V37.968H20.6666V33.968C20.6666 31.687 18.9477 29.968 16.6666 29.968H6.66663Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6666 13.3013C9.38558 13.3013 7.66663 15.0202 7.66663 17.3013V18.9679C7.66663 21.249 9.38558 22.9679 11.6666 22.9679C13.9477 22.9679 15.6666 21.249 15.6666 18.9679V17.3013C15.6666 15.0202 13.9477 13.3013 11.6666 13.3013ZM5.66663 17.3013C5.66663 13.9157 8.28101 11.3013 11.6666 11.3013C15.0522 11.3013 17.6666 13.9157 17.6666 17.3013V18.9679C17.6666 22.3536 15.0522 24.9679 11.6666 24.9679C8.28101 24.9679 5.66663 22.3536 5.66663 18.9679V17.3013Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6666 2.30127C20.6666 1.74898 21.1143 1.30127 21.6666 1.30127H38.3333C38.8856 1.30127 39.3333 1.74898 39.3333 2.30127V13.9679C39.3333 14.5202 38.8856 14.9679 38.3333 14.9679H28.6666L22.2666 19.7679C21.9636 19.9952 21.5582 20.0318 21.2194 19.8624C20.8806 19.693 20.6666 19.3467 20.6666 18.9679V2.30127ZM22.6666 3.30127V16.9679L27.7333 13.1679C27.9064 13.0381 28.1169 12.9679 28.3333 12.9679H37.3333V3.30127H22.6666Z"
      />
    </svg>
  );
};

export default CustomerSupport;
